import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { sendInviteTeamMember } from "app/slices/team/thunk";
import LRButton from "components/LRButton";
import LRInput from "components/LRInput";
import LRInputSelect from "components/LRInputSelect";
import LRModal from "components/LRModal";
import LRSuccessPopup from "components/LRSuccessPopup";
import useMobile from "hooks/useMobile";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { inviteMemberSchema, teamLoginSchema } from "utility/validators";

interface IInviteTeamMemberProps {
  isVisible: boolean;
  onClose(x: boolean): void;
}

interface IFormValues {
  email: string;
}
const InviteTeamMember = ({ isVisible, onClose }: IInviteTeamMemberProps) => {
  const dispatch = useAppDispatch();
  const accessTypes = useAppSelector(({ team }) => team.accessTypes);

  const [isMobile] = useMobile();
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState("READ_ONLY");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMemberInvitedSuccessModal, setShowMemberInvitedSuccessModal] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(inviteMemberSchema),
  });
  const handleSendInvite = (data: IFormValues) => {
    setIsSubmitting(true);
    if (!access?.length) {
      setIsSubmitting(false);
      return;
    }
    dispatch(
      sendInviteTeamMember({
        email: data.email,
        access,
        invite_url: `${process.env.REACT_APP_FRONTEND_HOST}/auth/team-invite`,
      })
    )
      .then(({ payload }) => {
        setEmail(data.email);
        if (payload.status === "success") {
          setShowMemberInvitedSuccessModal(true);
          reset();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return !showMemberInvitedSuccessModal ? (
    <LRModal isShortest={isMobile} isVisible={isVisible} onCloseModal={onClose} noPadding isMid>
      <div>
        <div className="w-full flex justify-between items-start space-x-10 px-6 pt-8 md:py-8 md:px-10 md:pb-8 md:border-b border-b-gray-border">
          <div className="space-y-2 text-left max-w-lg pr-2">
            <p className="text-base font-semibold text-black-80">Invite Team Member</p>
            <p className="grotesk text-xs text-black-20">
              Invite a team member to give them access to your business dashboard.
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                onClose(false);
              }}
              className="hidden sm:inline-block"
            >
              <i className="ri-close-fill ri-xl" />
            </button>
          </div>
        </div>
        <div className="px-4 pt-8 pb-6 md:py-6 md:px-10 space-y-4">
          <LRInput
            label="Email Address"
            placeholder="johndoe@xyz.com"
            inputRegister={register("email")}
            error={errors.email?.message}
          />
          <LRInputSelect
            label="Set access"
            placeholder="Select access"
            displayKey="key"
            selectedValueText={access}
            options={accessTypes.map((type) => ({ key: type }))}
            handleOptionSelect={(option) => {
              setAccess(option.key);
            }}
          />
        </div>
        <div className="container-end space-x-2 w-56 ml-auto mb-10 mx-10">
          <LRButton isGray isDisabled={isSubmitting} text="Cancel" onClick={() => {}} />
          <LRButton
            text="Send Invite"
            isLoading={isSubmitting}
            onClick={handleSubmit(handleSendInvite)}
          />
        </div>
      </div>
    </LRModal>
  ) : (
    <LRSuccessPopup
      isVisible={showMemberInvitedSuccessModal}
      onClose={() => {
        onClose(false);
        setShowMemberInvitedSuccessModal(false);
      }}
      title="Invite sent"
      message={
        <>
          An invite to join your team has been sent to <b>{email}</b>
        </>
      }
    />
  );
};

export default InviteTeamMember;
