import { useAppDispatch } from "app/hooks";
import { fetchTeamMembers, removeInviteTeamMember, removeTeamMember } from "app/slices/team/thunk";
import LRButton from "components/LRButton";
import LRModal from "components/LRModal";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useState } from "react";

interface IRevokeInviteProps {
  isVisible: boolean;
  memberEmail: string;
  onClose(x: boolean): void;
}

const RevokeInvite = ({ isVisible, onClose, memberEmail }: IRevokeInviteProps) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMemberDeletedSuccessModal, setShowMemberDeletedSuccessModal] = useState(false);

  const handleSendInvite = () => {
    setIsSubmitting(true);
    dispatch(
      removeInviteTeamMember({
        email: memberEmail,
      })
    )
      .then(({ payload }) => {
        if (payload.status === "success") {
          setShowMemberDeletedSuccessModal(true);
          dispatch(fetchTeamMembers({}));
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return !showMemberDeletedSuccessModal ? (
    <LRModal isVisible={isVisible} onCloseModal={onClose} noPadding isMid>
      <div>
        <div className="w-full flex justify-between items-start space-x-2 p-10 pb-8 border-b border-b-gray-border">
          <div className="space-y-4 text-left pr-2">
            <p className="text-base font-semibold text-black-80">Revoke Invite</p>
            <p className="grotesk text-xs text-black-20">
              When a team member's invite is revoked, the earlier sent invite link becomes
              non-functional. Are you sure you want to continue?
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                onClose(false);
              }}
              className="bg-transparent border-none hidden sm:inline-block"
            >
              <i className="ri-close-fill ri-xl" />
            </button>
          </div>
        </div>
        <div className="container-end space-x-2 w-1/2 ml-auto mb-10 mx-10 pt-8">
          <div className="inline-block">
            <LRButton isGray isDisabled={isSubmitting} text="Cancel" onClick={() => {}} />
          </div>
          <div className="inline-block">
            <LRButton
              isRed
              text="Yes, Remove"
              isLoading={isSubmitting}
              onClick={handleSendInvite}
            />
          </div>
        </div>
      </div>
    </LRModal>
  ) : (
    <LRSuccessPopup
      isVisible={showMemberDeletedSuccessModal}
      onClose={() => {
        onClose(false);
        setShowMemberDeletedSuccessModal(false);
      }}
      title="Invite Revoked"
      message={
        <>
          <b>{memberEmail}</b> will no longer be able to create an account with the invite link.
        </>
      }
    />
  );
};

export default RevokeInvite;
