import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchTeamMembers, updateInviteTeamMember } from "app/slices/team/thunk";
import LRButton from "components/LRButton";
import LRInput from "components/LRInput";
import LRInputSelect from "components/LRInputSelect";
import LRModal from "components/LRModal";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { inviteMemberSchema, teamLoginSchema } from "utility/validators";

interface IManageTeamMemberProps {
  isVisible: boolean;
  reference: string;
  memberEmail: string;
  role: string;
  onClose(x: boolean): void;
}

interface IFormValues {
  email: string;
}
const ManageTeamMember = ({
  isVisible,
  onClose,
  reference,
  memberEmail,
  role,
}: IManageTeamMemberProps) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState(role);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMemberEditedSuccessModal, setShowMemberEditedSuccessModal] = useState(false);

  const accessTypes = useAppSelector(({ team }) => team.accessTypes);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(inviteMemberSchema),
    defaultValues: {
      email: memberEmail,
    },
  });
  const handleSendInvite = (data: IFormValues) => {
    setIsSubmitting(true);
    if (!access?.length) return;
    dispatch(
      updateInviteTeamMember({
        reference,
        data: {
          access,
        },
      })
    )
      .then(({ payload }) => {
        setEmail(data.email);
        if (payload.status === "success") {
          setShowMemberEditedSuccessModal(true);
          dispatch(fetchTeamMembers({}));
          reset();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return !showMemberEditedSuccessModal ? (
    <LRModal isVisible={isVisible} onCloseModal={onClose} noPadding isMid>
      <div>
        <div className="w-full flex justify-between items-start space-x-10 p-10 pb-8 border-b border-b-gray-border">
          <div className="space-y-3 text-left max-w-lg pr-2">
            <p className="text-base font-semibold text-black-80">Manage access</p>
            <p className="grotesk text-xs text-black-20">
              Manage your team member’s access to do more or less.
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                onClose(false);
              }}
              className="hidden sm:inline-block"
            >
              <i className="ri-close-fill ri-xl" />
            </button>
          </div>
        </div>
        <div className="p-10 pt-8 space-y-4">
          <LRInput
            isDisabled
            label="Email Address"
            placeholder="johndoe@xyz.com"
            inputRegister={register("email")}
          />
          <LRInputSelect
            noIcon
            label="Set access"
            placeholder="Select access"
            displayKey="key"
            selectedValueText={access}
            options={accessTypes.map((type) => ({ key: type }))}
            handleOptionSelect={(option) => {
              setAccess(option.key);
            }}
          />
        </div>
        <div className="container-end space-x-2 w-1/2 ml-auto mb-10 mx-10">
          <div className="inline-block">
            <LRButton isGray isDisabled={isSubmitting} text="Cancel" onClick={() => {}} />
          </div>
          <div className="inline-block">
            <LRButton
              text="Update Access"
              isLoading={isSubmitting}
              onClick={handleSubmit(handleSendInvite)}
            />
          </div>
        </div>
      </div>
    </LRModal>
  ) : (
    <LRSuccessPopup
      isVisible={showMemberEditedSuccessModal}
      onClose={() => {
        onClose(false);
        setShowMemberEditedSuccessModal(false);
      }}
      title="Access Updated"
      message={
        <>
          You have updated the access of <b>{email}</b>
        </>
      }
    />
  );
};

export default ManageTeamMember;
